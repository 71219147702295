function ProjectBox({ data }) {
  const { title, image, link, description } = data;
  return (
    <div className="project">
      <div className="project-wraper">
        <div className="project-inmg">
          {" "}
          <img src={image} alt="Project 1" />
        </div>

        <h3>{title}</h3>
      </div>

      <p>{description}</p>
      <a className="btb-linck" href={link}>
        View Project
      </a>
    </div>
  );
}

export default ProjectBox;
