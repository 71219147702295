import Header from "../component/header";
import AboutMe from "../component/AboutMe";
import Introduction from "../component/Introduction";
import Projects from "../component/Projects";
import Contact from "../component/Contact";
import GradientNavigation from "../component/GradientNavigation";
import TimeLime from "../component/TimeLime";
import Navland from "../component/Navland";
import PathVisualizer from "../component/PathVisualizer";
function Home() {
  return (
    <div className="home-wrapper">
      <Navland />
      <GradientNavigation />
      <div className="wraprttt">
        <Introduction />
        <AboutMe />
        <Projects />
        <TimeLime />
        <PathVisualizer />
      </div>
    </div>
  );
}

export default Home;
