const projectInfo = [
  {
    image: "https://meettum.com/IMG_0193.png",
    title: "Meettum",
    link: "https://meettum.com/register",
    description:
      "Meettum combines collaboration, meeting scheduling ,empowering users to enhance productivity and teamwork",
  },
  {
    image: "https://myfitsta.com/static/media/logo.84ddd6d7.png",
    title: "MYFITSTA",
    link: "https://myfitsta.com/home",
    description:
      "A social media platforms that allows you to post fitness content.",
  },
  {
    image:
      "https://user-images.githubusercontent.com/48225800/212520648-4bae8ef9-d926-4812-af2f-f96c93fc6536.png",
    title: "Mixcy",
    link: "https://github.com/project-musicx",
    description:
      "A Music player that work with Spotify to play and sync Music ",
  },
  {
    image:
      "https://github.com/kennethoo/Inventory_tracking/assets/48225800/e9bb9207-d64b-41f9-9b6b-38bd538be88d",
    title: "Inventory_tracking",
    link: "https://github.com/kennethoo/Inventory_tracking",
    description:
      "This application is an inventory tracking web application that has a basic front end in React and a backend in Node.js. ",
  },
  {
    image:
      "https://user-images.githubusercontent.com/48225800/212520480-0f3c62d7-d43c-4650-8bee-5b890edc72d2.png",
    title: "Trivia Game",
    link: "https://precious-figolla-0107c5.netlify.app/",
    description:
      "A Realtime trivia game for people that love coding competition. ",
  },
  {
    image:
      "https://user-images.githubusercontent.com/48225800/236940267-693ab5c2-3eae-4a5d-bb6a-8826589ebae4.png",
    title: "Crypto Market",
    link: "https://github.com/kennethoo/cryptomarket",
    description: "A Crypto market Ui application",
  },
  {
    image:
      "https://play-lh.googleusercontent.com/spMsx1fd5yXQDzmffQIU8S6H9i3Bzh-y5cD26lGvsT2Wds6obm8_xr6BC-8LHnTbY2w=w480-h960-rw",
    title: "3D-Tic-Tac-Toe",
    link: "https://github.com/kennethoo/3D-Tic-Tac-Toe",
    description: "A Tic-Tac-Toe Game that but with mutiplayer and 3D layer",
  },
];

module.exports = projectInfo;
