function TimeLime() {
  return (
    <div id="experience">
      <h2>Experience</h2>
      <div className="timeline">
        <div className="container left">
          <div className="content">
            <h3>Software Engineer Intern</h3>
            <p>
              Develop applications web feature for Cambly Admin and Core Product
              using Typescript, Javascript and React
            </p>
            <p>
              Developed lessonActivityLog using python to manage students and
              tutors classroom lifecycle creating more than 10000+ document/day.
            </p>
            <p>Create documentation and perform testing for new feature .</p>
          </div>
        </div>
        <div className="container right">
          <div className="content">
            <h3>Software Engineer Intern</h3>
            <p>
              Developed a serveless platform using golang and Kubernetes to
              deploy function in the Linkedin infrastructure .
            </p>
            <p>
              Collaborated effectively with team members while maintaining
              strong sense of individual responsibility.
            </p>
            <p>
              Worked with software team members to enhance and sustain automated
              builds and integration tests.
            </p>
          </div>
        </div>
        <div className="container left">
          <div className="content">
            <h3>Frontend Engineer</h3>
            <p>
              Designed client-side and server side architecture for a MVP Crypto
              base Application using Node.js.
            </p>
            <p>
              Reviewed project specifications and designed technology solutions
              that exceeded performance expectations
            </p>
            <p>
              Created web and mobile versions of the MVP using React Native.
            </p>
          </div>
        </div>
        <div className="container right">
          <div className="content">
            <h3>Full Stack Engineer</h3>
            <p>Seeing through a project from conception to finished product.</p>
            <p>Led database, web framework and API development projects.</p>
            <p>Ensuring cross-platform optimization for mobile phones.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

//

export default TimeLime;
