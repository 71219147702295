import React, { Component } from "react";
import { VscChromeClose } from "react-icons/vsc";
import { GiHamburgerMenu } from "react-icons/gi";
class Navland extends Component {
  state = {
    mode: false,
    nav: false,
    theme: "",
  };
  openNav = (data) => {
    this.setState({
      nav: data,
    });
  };

  loadTheme = (theme) => {
    const root = document.querySelector("#root");
    root.setAttribute("color-scheme", `${theme}`);
    localStorage.setItem("mode", theme);
    this.setState({
      theme: theme,
    });
  };

  componentDidMount = () => {
    theme: localStorage.getItem("mode");
  };
  render() {
    return (
      <div className="wrapeeriror">
        <div className={`hearderdff ${this.state.mode ? "active" : ""}  `}>
          <div className="hiolt-the-name">
            <a href="/"> AMZAT </a>
          </div>
          <div className="hold-shjjrj">
            <div className="hlsjjjr">
              <div>
                <a href="/#about-me">About Me</a>
              </div>
            </div>

            <div className="hlsjjjr">
              <div>
                <a href="/#recent-projects"> Recent Projects</a>
              </div>
            </div>
            <div className="hlsjjjr">
              <div>
                <a href="/#experience">Experience</a>
              </div>
            </div>
            <div className="hlsjjjr">
              <div>
                <a href="/#path-visualizer">Path Visualizer</a>
              </div>
            </div>
          </div>

          <div className="wraperr-takrkrk"></div>

          <div
            onClick={() => {
              this.openNav(true);
            }}
            className="heamber-varr">
            <GiHamburgerMenu />
          </div>
        </div>

        <div
          className={`side-bahrirr  ${this.state.mode ? "activee" : ""}    ${
            this.state.nav ? "active" : ""
          }`}>
          <div className="hold-shjtjrj">
            <div className="hlsjjjtjtjjtttr">
              <div className="wrparpprpr">
                <div className="holthe-logo"></div>
                <div className="hold-the-logo"></div>
                <div className="hiolt-the-name">AMZAT</div>
              </div>
              <div
                onClick={() => {
                  this.openNav(false);
                }}
                className="close-that">
                <VscChromeClose />
              </div>
            </div>
            <div className="hlsjjjtjtjjr">
              <a
                onClick={() => {
                  this.openNav(false);
                }}
                href="/#about-me">
                About Me
              </a>
            </div>
            <div className="hlsjjjtjtjjr">
              <a
                onClick={() => {
                  this.openNav(false);
                }}
                href="/#recent-projects">
                Recent Projects
              </a>
            </div>
            <div className="hlsjjjtjtjjr">
              <a
                onClick={() => {
                  this.openNav(false);
                }}
                href="/#experience">
                Experience
              </a>
            </div>
            <div className="hlsjjjtjtjjr">
              <a
                onClick={() => {
                  this.openNav(false);
                }}
                href="/#path-visualizer">
                Path Visualizer
              </a>
            </div>
            <div className="wrsjjrrjr-ejrr"></div>
          </div>
          <div className="scribrrnjr"></div>
        </div>
      </div>
    );
  }
}

export default Navland;
