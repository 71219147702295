import { TfiLinkedin } from "react-icons/tfi";
import { AiFillGithub, AiOutlineMail } from "react-icons/ai";
function Introduction() {
  return (
    <section id="Introduction-me-section">
      <div id="Introduction-text-about-me">
        <p id="into-start">HELLO</p>

        <p id="smaldes">
          I am a software enginner who loves to learn and develop new
          technologies.
        </p>

        <div className="social-media">
          <a
            href="https://www.linkedin.com/in/kenneth-a-gandonou-5911541a1/"
            className="social-box"
          >
            <TfiLinkedin />
          </a>
          <a href="https://github.com/kennethoo" className="social-box">
            <AiFillGithub />
          </a>
          <a href="mailto: kcgandonou19@gmail.com" className="social-box">
            <AiOutlineMail />
          </a>
        </div>
      </div>
    </section>
  );
}
export default Introduction;
