function AboutMe() {
  return (
    <section id="about-me">
      <div className="containerr">
        <h2>About Me</h2>

        <p>
          Hi Here!! My name is Amzat but you can call me Kenneth. . I'm
          passionate about different engineering fields from Infrastructure to
          product engineering and building new technologies that solve complex
          problems. I'm truly excited about the future of technology and finding
          the best way to build and deliver products in the world.
        </p>

        <p>
          When I'm not working, I enjoy watching anime going to the gyms and
          playing some sport .
        </p>
        <p>
          Feel free to take a look at my portfolio and get in touch at
          kcgandonou19@gmail.com!
        </p>
      </div>
    </section>
  );
}
export default AboutMe;
