import { useState, useEffect } from "react";
import { BiLocationPlus, BiTargetLock } from "react-icons/bi";
function TraversePath({ row, col }) {
  const [isTraveseBegin, setIsTraveseBegin] = useState(false);
  const [beginNode, setBeginNode] = useState(null);
  const [matrix, setMatrix] = useState(buildMatrix(row, col));
  const [endNode, setEndNode] = useState(null);
  const [isBfs, setIsBfs] = useState(false);
  const [isDfs, setIsDfs] = useState(false);

  const handleClick = (row, col) => {
    if (isTraveseBegin) return;
    const { isEndGoal, isStartNode, isOb } = matrix[row][col];
    if (isStartNode) {
      let newMatrix = matrix.slice();
      newMatrix[row][col].isStartNode = false;
      setMatrix(newMatrix);
      setBeginNode(null);
    } else if (isEndGoal) {
      let newMatrix = matrix.slice();
      newMatrix[row][col].isEndGoal = false;
      setMatrix(newMatrix);
      setEndNode(null);
    } else if (!beginNode && !isOb) {
      let newMatrix = matrix.slice();
      newMatrix[row][col].isStartNode = true;
      setMatrix(newMatrix);
      setBeginNode([row, col]);
    } else if (!endNode && !isOb) {
      let newMatrix = matrix.slice();
      newMatrix[row][col].isEndGoal = true;
      setMatrix(newMatrix);
      setEndNode([row, col]);
    } else if (isOb) {
      let newMatrix = matrix.slice();
      newMatrix[row][col].isOb = false;
      setMatrix(newMatrix);
    } else if (endNode && beginNode) {
      let newMatrix = matrix.slice();
      newMatrix[row][col].isOb = true;
      setMatrix(newMatrix);
    }
  };

  useEffect(() => {
    resetVisualizer();
  }, [row, col]);

  function traverseMatrixUsingBFS() {
    if (!beginNode || !endNode) return;
    let path = [beginNode[0] + "," + beginNode[1]];
    if (isTraveseBegin) return;
    const queue = [[...beginNode, path.slice()]];
    setIsTraveseBegin(true);
    setIsBfs(true);
    let count = 0;
    let found = false;
    const [endRow, endCol] = endNode;
    let direction = [
      [0, -1],
      [0, 1],
      [1, 0],
      [-1, 0],
    ];

    while (queue.length) {
      if (found) {
        break;
      }
      let [row, col, path] = queue.shift();
      count += 15;
      if (row === endRow && col === endCol) {
        found = true;
        showPath(path);
        return;
      } else {
        let newMatrix = matrix.slice();
        newMatrix[row][col].isActive = true;
        newMatrix[row][col].delayTransition = count;
        setMatrix(newMatrix);
      }
      for (let [directionRow, directionCol] of direction) {
        let newRow = row + directionRow;
        let newCol = col + directionCol;
        let pathCo = newRow + "," + newCol;
        if (
          isInBound(newRow, newCol, matrix) &&
          !matrix[newRow][newCol].isVisited &&
          !matrix[newRow][newCol].isOb
        ) {
          queue.push([newRow, newCol, [...path, pathCo]]);
          let newMatrix = matrix.slice();
          newMatrix[newRow][newCol].isVisited = true;
          setMatrix(newMatrix);
        }
      }
    }
  }

  function traverseMatrixUsingDFS() {
    if (!beginNode || !endNode) return;
    const [startRow, startCol] = beginNode;
    const [endRow, endCol] = endNode;
    let count = 0;
    let direction = [
      [0, -1],
      [0, 1],
      [1, 0],
      [-1, 0],
    ];

    let found = false;
    setIsDfs(true);
    setIsTraveseBegin(true);
    const dfs = (row, col) => {
      if (found) {
        return;
      }
      if (!isInBound(row, col, matrix)) return;
      if (matrix[row][col].isVisited) return;
      if (matrix[row][col].isOb) return;
      if (matrix[row][col].isEndGoal) {
        found = true;
        return;
      }
      count += 20;
      if (row === endRow && col === endCol) {
        found = true;
        return;
      } else {
        let newMatrix = matrix.slice();
        newMatrix[row][col].isActive = true;
        newMatrix[row][col].isVisited = true;
        newMatrix[row][col].delayTransition = count;
        setMatrix(newMatrix);
      }
      for (let [directionRow, directionCol] of direction) {
        let newRow = row + directionRow;
        let newCol = col + directionCol;
        dfs(newRow, newCol);
      }
    };
    dfs(startRow, startCol);
  }
  function resetVisualizer() {
    setIsBfs(false);
    setIsDfs(false);
    setMatrix([]);
    setMatrix(buildMatrix(row, col));
    setBeginNode(null);
    setEndNode(null);
    setIsTraveseBegin(false);
  }
  function showPath(pathCos) {
    pathCos.pop();
    for (let path of pathCos) {
      let [row, col] = path.split(",");
      let newMatrix = matrix.slice();
      newMatrix[row][col].lightPath = true;
      setMatrix(newMatrix);
    }
  }

  return (
    <div className="path-wraoer">
      <div className="controler-section">
        <button
          onClick={traverseMatrixUsingBFS}
          className={`btn-button-al ${isBfs && "active"}`}
        >
          BFS
        </button>
        <button
          onClick={traverseMatrixUsingDFS}
          className={`btn-button-al ${isDfs && "active"}`}
        >
          DFS
        </button>
        <button onClick={resetVisualizer} className="btn-button-al">
          RESET
        </button>
      </div>
      {matrix.map((_, index) => {
        return (
          <div key={index} className="row-box">
            {matrix[index].map((item, index) => {
              const {
                key,
                isActive,
                isStartNode,
                row,
                col,
                isEndGoal,
                delayTransition,
                zIndex,
                isOb,
                lightPath,
              } = item;
              const nodeClass = isStartNode
                ? "startNode"
                : isEndGoal
                ? "endGoal"
                : isActive
                ? "active"
                : isOb
                ? "obts"
                : "";
              const showPathClass = lightPath && "showPath";
              const style = isActive
                ? {
                    transitionDelay: `${delayTransition}ms`,
                  }
                : { zIndex };

              return (
                <div
                  style={style}
                  onClick={() => handleClick(row, col)}
                  key={key}
                  className={`col-box ${nodeClass} ${showPathClass} `}
                >
                  {isStartNode && <BiLocationPlus />}
                  {isEndGoal && <BiTargetLock />}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

function buildMatrix(row, col) {
  let matrix = [];
  for (let nRow = 0; nRow < row; nRow++) {
    matrix[nRow] = [];
    for (let nCol = 0; nCol < col; nCol++) {
      let node = {
        key: nRow + "," + nCol,
        row: nRow,
        col: nCol,
        isActive: false,
        isStartNode: false,
        isEndGoal: false,
        isVisited: false,
        delayTransition: 0,
        zIndex: 0,
        isOb: false,
        lightPath: false,
      };
      matrix[nRow][nCol] = node;
    }
  }
  return matrix;
}

export default TraversePath;

function isInBound(row, col, grid) {
  if (row < 0 || row >= grid.length) return false;
  if (col < 0 || col >= grid[0].length) return false;
  return true;
}
