import ProjectInfo from "../projectData";
import ProjectBox from "./ProjectBox";
import { useEffect } from "react";

function Projects() {
  return (
    <section id="recent-projects">
      <h2>Recent Projects</h2>
      <div className="container_project">
        {ProjectInfo.map((item) => {
          return <ProjectBox key={item.title} data={item} />;
        })}
      </div>
    </section>
  );
}
export default Projects;
