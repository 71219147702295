import TraversePath from "./TraversePath";
import useWindowDimensions from "../hooks/useWindowDimensions";

function PathVisualizer() {
  const { width, height } = useWindowDimensions();
  const { rows, cols } = calculateBestLayout(width, height, 50, 50);
  return (
    <section id="path-visualizer">
      <h2>Path Visualizer For Fun</h2>
      <div className="wraper-path">
        <TraversePath row={rows} col={cols} />
      </div>
    </section>
  );
}
export default PathVisualizer;

function calculateBestLayout(screenWidth, screenHeight, cellWidth, cellHeight) {
  const rows = Math.floor(screenHeight / cellHeight) - 1;
  const cols = Math.floor(screenWidth / cellWidth) - 1;

  return { rows, cols };
}
